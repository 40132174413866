/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LogOut, Settings, Unlock } from 'react-feather'
import { Link } from 'react-router-dom'
import { stateActions } from '../helpers/store'
import { logout } from '../helpers/common'

function Header() {
  const dispatch = useDispatch()
  const redux = useSelector(state => state.redux)

  const handleOpenSidebar = () => {
    dispatch(stateActions.setState({ openSidebar: !redux.openSidebar }))
  }

  return (
    <nav className='navbar navbar-expand navbar-light navbar-bg'>
      <a className='sidebar-toggle js-sidebar-toggle' onClick={handleOpenSidebar}>
        <i className='hamburger align-self-center'></i>
      </a>
      <div className='navbar-collapse collapse'>
        <ul className='navbar-nav navbar-align'>
          {/* <li className='nav-item dropdown'>
            <a className='nav-icon dropdown-toggle' href='#' id='alertsDropdown' data-bs-toggle='dropdown'>
              <div className='position-relative'>
                <Bell className='align-middle' />
                <span className='indicator'>4</span>
              </div>
            </a>
            <div className='dropdown-menu dropdown-menu-lg dropdown-menu-end py-0' aria-labelledby='alertsDropdown'>
              <div className='dropdown-menu-header'>
                4 New Notifications
              </div>
              <div className='list-group'>
                <a href='#' className='list-group-item'>
                  <div className='row g-0 align-items-center'>
                    <div className='col-2'>
                      <AlertCircle className='text-danger' />
                    </div>
                    <div className='col-10'>
                      <div className='text-dark'>Update completed</div>
                      <div className='text-muted small mt-1'>Restart server 12 to complete the update.</div>
                      <div className='text-muted small mt-1'>30m ago</div>
                    </div>
                  </div>
                </a>
                <a href='#' className='list-group-item'>
                  <div className='row g-0 align-items-center'>
                    <div className='col-2'>
                      <Bell className='text-warning' />
                    </div>
                    <div className='col-10'>
                      <div className='text-dark'>Lorem ipsum</div>
                      <div className='text-muted small mt-1'>Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
                      <div className='text-muted small mt-1'>2h ago</div>
                    </div>
                  </div>
                </a>
                <a href='#' className='list-group-item'>
                  <div className='row g-0 align-items-center'>
                    <div className='col-2'>
                      <Home className='text-primary' />
                    </div>
                    <div className='col-10'>
                      <div className='text-dark'>Login from 192.186.1.8</div>
                      <div className='text-muted small mt-1'>5h ago</div>
                    </div>
                  </div>
                </a>
                <a href='#' className='list-group-item'>
                  <div className='row g-0 align-items-center'>
                    <div className='col-2'>
                      <UserPlus className='text-success' />
                    </div>
                    <div className='col-10'>
                      <div className='text-dark'>New connection</div>
                      <div className='text-muted small mt-1'>Christina accepted your request.</div>
                      <div className='text-muted small mt-1'>14h ago</div>
                    </div>
                  </div>
                </a>
              </div>
              <div className='dropdown-menu-footer'>
                <a href='#' className='text-muted'>Show all notifications</a>
              </div>
            </div>
          </li> */}
          <li className='nav-item dropdown'>
            <a className='nav-icon dropdown-toggle d-inline-block d-sm-none' href='#' data-bs-toggle='dropdown'>
              <Settings className='align-middle' />
            </a>
            <a className='nav-link dropdown-toggle d-none d-sm-inline-block' href='#' data-bs-toggle='dropdown'>
              <span className='text-dark me-1'>{redux.profile.name}</span>
            </a>
            <div className='dropdown-menu dropdown-menu-end'>
              <Link to='/account' className='dropdown-item'><Unlock className='align-middle me-1' /> Akun</Link>
              <div className='dropdown-divider'></div>
              <a className='dropdown-item' onClick={logout}><LogOut className='align-middle me-1' /> Keluar</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header
