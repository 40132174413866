import dayjs from 'dayjs'
import Cookies from 'universal-cookie'
import { axiosTenant } from './axios'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import store from './store'

dayjs.extend(utc)
dayjs.extend(timezone)

export const cookies = new Cookies(null, { path: '/' })

export const logout = () => {
  cookies.remove('token')
  cookies.remove('api_base_path')
  window.location.href = '/login'
}

export const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=')
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
        return params
      }, {}
      )
    : {}
}

export const displayDateTime = (date, displayFormat, timezone) => {
  if (timezone) {
    return dayjs(date).tz(timezone).format(displayFormat || 'DD/MM/YYYY HH:mm')
  }
  return dayjs(date).tz(store.getState().redux.company?.timezone ?? 'Asia/jakarta').format(displayFormat || 'DD/MM/YYYY HH:mm')
}

export const toRupiah = (angka) => {
  let rupiah = ''
  const angkarev = angka.toString().split('').reverse().join('')
  for (let i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.'
  return rupiah.split('', rupiah.length - 1).reverse().join('')
}

export const loadParticipantCategoryOptions = (inputValue) => {
  return new Promise((resolve) => {
    axiosTenant()
      .get(`/student-categories?keyword=${inputValue}&page=null`)
      .then((result) => {
        resolve(result.data.map((item) => {
          const label = item.name
          return { label, value: item.id }
        }))
      })
  })
}

export const loadParticipantOptions = (inputValue, categoryIds) => {
  let apiEndpoint = `/students?keyword=${inputValue}&page=null`
  if (categoryIds) apiEndpoint += `&student_category_id=${categoryIds}`
  return new Promise((resolve) => {
    axiosTenant()
      .get(apiEndpoint)
      .then((result) => {
        resolve(result.data.map((item) => {
          let label = item.name
          if (item.student_category) {
            label += ` - ${item.student_category.name}`
          }
          return { label, value: item.id }
        }))
      })
  })
}
