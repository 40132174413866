import React, { lazy, Suspense } from 'react'
import {
  Route,
  Routes,
  Outlet,
  Navigate,
  BrowserRouter,
} from 'react-router-dom'
import { Provider } from 'react-redux'
import './assets/scss/app.scss'
import Sidebar from './layout/Sidebar'
import Header from './layout/Header'
import Footer from './layout/Footer'
import store from './helpers/store'
import AuthCheck from './middleware/AuthCheck'
import Ott from './components/auth/OttComponent'

const Dasboard = lazy(() => import('./components/dashboard/DashboardComponent'))
const Login = lazy(() => import('./components/auth/LoginComponent'))
const ForgotPassword = lazy(() => import('./components/auth/ForgotPasswordComponent'))
const ResetPassword = lazy(() => import('./components/auth/ResetPasswordComponent'))
const AttendanceReport = lazy(() => import('./components/attendance-report/AttendanceReportComponent'))
const AttendancePage = lazy(() => import('./components/attendance-page/AttendancePageComponent'))
const AttendancePublic = lazy(() => import('./components/attendance-public/AttendancePublicComponent'))
const Participant = lazy(() => import('./components/participant/ParticipantComponent'))
const ParticipantDetail = lazy(() => import('./components/participant/ParticipantDetailComponent'))
const ParticipantCategory = lazy(() => import('./components/participant/ParticipantCategoryComponent'))
const CompanyProfile = lazy(() => import('./components/company-profile/CompanyProfileComponent'))
const Subscription = lazy(() => import('./components/subscription/SubscriptionComponent'))
const Admin = lazy(() => import('./components/admin/AdminComponent'))
const Account = lazy(() => import('./components/account/AccountComponent'))

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div className='w-100 text-center'><div className='mt-4 spinner-border text-primary' /></div>}>
        <Routes>
          <Route exact path='/' element={<Navigate to='/login' replace />} />
          <Route exact path='/Ott' Component={Ott} />
          <Route exact path='/login' Component={Login} />
          <Route exact path='/forgot-password' Component={ForgotPassword} />
          <Route exact path='/auth/reset-password' Component={ResetPassword} />
          <Route exact path='/a/:id' Component={AttendancePublic} />
          <Route
            element={
              <AuthCheck>
                <div className='wrapper'>
                  <Sidebar />
                  <div className='main'>
                    <Header />
                    <main className='content'>
                      <Outlet />
                    </main>
                    <Footer />
                  </div>
                </div>
              </AuthCheck>
            }
          >
            <Route exact path='/dashboard' Component={Dasboard} />
            <Route exact path='/attendance-report' Component={AttendanceReport} />
            <Route exact path='/attendance-page' Component={AttendancePage} />
            <Route exact path='/participant' Component={Participant} />
            <Route exact path='/participant/detail/:id' Component={ParticipantDetail} />
            <Route exact path='/participant/category' Component={ParticipantCategory} />
            <Route exact path='/company-profile' Component={CompanyProfile} />
            <Route exact path='/subscription' Component={Subscription} />
            <Route exact path='/admin' Component={Admin} />
            <Route exact path='/account' Component={Account} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter >
  )
}

function App() {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  )
}

export default App
