import axios from 'axios'
// import store from './store'
import { cookies } from './common'

export const axiosPublic = () => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  })
}

export const axiosTenant = () => {
  if(!cookies.get('token') || !cookies.get('api_base_path')) {
    window.location.href = '/login'
  } 
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}${cookies.get('api_base_path')}`,
    headers: {
      Authorization: `Bearer ${cookies.get('token')}`
    }
  })
}
