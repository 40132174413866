import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { axiosPublic } from '../../helpers/axios'
import { cookies, logout } from '../../helpers/common'

function Ott() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (searchParams.get('token')) {
      axiosPublic().post('/ott-get', { ott_token: searchParams.get('token') })
        .then(res => {
          // console.log(res)
          cookies.set('token', res.data.token)
          cookies.set('api_base_path', res.data.api_base_path)
          navigate('/dashboard', { replace: true })
        })
        .catch(err => {
          console.log(err)
          logout()
        })
    } else {
      navigate('/login', { replace: true })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className='w-100 text-center'><div className='mt-4 spinner-border text-primary' /></div>
  )
}

export default Ott
