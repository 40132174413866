import { createSlice, configureStore } from '@reduxjs/toolkit'

const initialState = {
  openSidebar: true,
  company: {
    name: '',
    address: ''
  }
}

const stateSlice = createSlice({
  name: 'state',
  initialState: initialState,
  reducers: {
    setState(state, action) {
      state = {
        ...state,
        ...action.payload
      }
      return state
    }
  }
})

const store = configureStore({
  reducer: {
    redux: stateSlice.reducer
  }
})

export const stateActions = stateSlice.actions
export default store
