import React, { useEffect } from 'react'
import { axiosTenant } from '../helpers/axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { cookies } from '../helpers/common'
import { useDispatch, useSelector } from 'react-redux'
import { stateActions } from '../helpers/store'

function AuthCheck(props) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const redux = useSelector(state => state.redux)

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line
  }, [])

  const getProfile = () => {
    Promise.all([
      axiosTenant().get('/myprofile'),
      axiosTenant().get('/settings/company')
    ])
      .then(async ([res1, res2]) => {
        const profileRes = await res1.data
        const companyRes = await res2.data
        document.title = `Scoola - ${companyRes.name}`
        dispatch(stateActions.setState({ profile: profileRes, company: companyRes }))
      })
      .catch(err => {
        cookies.remove('token')
        cookies.remove('api_base_path')
        navigate('/login', { state: { lastPage: location.pathname ?? '' } })
      })
  }

  return (
    <>
      {redux.profile && props.children}
    </>
  )
}

export default AuthCheck
